/* original source for this file
  https://gist.github.com/igoro00/99e9d244677ccafbf39667c24b5b35ed
*/

.react-datepicker {
  font-family: unset;
  font-size: unset;
  border-color: var(--chakra-colors-grayBlue-700);
  border-radius: 0;
  color: var(--chakra-colors-brand-950);
  background: var(--chakra-colors-brand-50);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 44px;
}

.react-datepicker__header {
  background-color: var(--chakra-colors-brand-50);
  border-radius: 0;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--chakra-colors-grayBlue-700);
}

.react-datepicker__time-container {
  width: 100px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--chakra-colors-brand-950);
}

.react-datepicker__month {
  background-color: var(--chakra-colors-brand-50);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-container .react-datepicker__time {
  background: var(--chakra-colors-brand-50);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--chakra-colors-grayBlue-700);
}

.react-datepicker__day {
  color: var(--chakra-colors-brand-950);
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-grayBlue-700);
}

.react-datepicker__day--disabled {
  color: var(--chakra-colors-brand-300);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: var(--chakra-colors-brand-300);
}

.react-datepicker__day--keyboard-selected {
  background: var(--chakra-colors-brand-50);
}

.react-datepicker__day--today {
  font-weight: unset;
}
.react-datepicker__day-name {
  color: var(--chakra-colors-brand-950);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-brand-100);
  font-weight: normal;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--chakra-colors-brand-600);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray);
}
